import { useCallback } from 'react'
import { Modal, ModalBody, Text, Button, Flex, InjectedModalProps } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { useAppDispatch } from 'state'
import { clearAllTransactions } from 'state/transactions/actions'
import { chains } from 'utils/wagmi'
import { useAccount } from 'wagmi'
import { AutoRow } from '../../Layout/Row'
import Transaction from './Transaction'
import ConnectWalletButton from '../../ConnectWalletButton'
import styled from 'styled-components'

function renderTransactions(transactions: TransactionDetails[], chainId: number) {
  return (
    <Flex flexDirection="column">
      {transactions.map((tx) => {
        return <Transaction key={tx.hash + tx.addedTime} tx={tx} chainId={chainId} />
      })}
    </Flex>
  )
}

const ClearAll = styled.div`
  display: flex;
  justify-content: end;
`

const WrapperOverflow = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.backgroundGrey};
    border-radius: 4px;
  }
`

const TransactionsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const { address: account } = useAccount()
  const dispatch = useAppDispatch()
  const sortedRecentTransactions = useAllSortedRecentTransactions()

  const { t } = useTranslation()

  const hasTransactions = !isEmpty(sortedRecentTransactions)

  const clearAllTransactionsCallback = useCallback(() => {
    dispatch(clearAllTransactions())
  }, [dispatch])

  return (
    <Modal
      minWidth="352px"
      title={t('Recent Transactions')}
      headerBackground="gradientCardHeader"
      onDismiss={onDismiss}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {account ? (
        <ModalBody style={{ width: '100%', paddingLeft: '0', paddingRight: '0' }}>
          {hasTransactions ? (
            <>
              <WrapperOverflow>
                {Object.entries(sortedRecentTransactions).map(([chainId, transactions]) => {
                  const chainIdNumber = Number(chainId)
                  const groupedTransactions = groupBy(Object.values(transactions), (trxDetails) =>
                    Boolean(trxDetails.receipt),
                  )

                  const confirmed = groupedTransactions.true ?? []
                  const pending = groupedTransactions.false ?? []

                  return (
                    <div key={`transactions#${chainIdNumber}`}>
                      <Text fontSize="12px" color="textSubtle" mb="4px">
                        {chains.find((c) => c.id === chainIdNumber)?.name ?? 'Unknown network'}
                      </Text>
                      {renderTransactions(pending, chainIdNumber)}
                      {renderTransactions(confirmed, chainIdNumber)}
                    </div>
                  )
                })}
              </WrapperOverflow>
              <ClearAll>
                <Button width="100%" variant="tertiary" scale="md" onClick={clearAllTransactionsCallback}>
                  {t('Clear all')}
                </Button>
              </ClearAll>
            </>
          ) : (
            <Text textAlign="center" color="darkGrey">
              {t('No recent transactions')}
            </Text>
          )}
        </ModalBody>
      ) : (
        <ConnectWalletButton />
      )}
    </Modal>
  )
}

export default TransactionsModal
