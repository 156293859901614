import { Price, Currency } from "@pancakeswap/swap-sdk-core";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import { Text, AutoRenewIcon, RefreshIcon } from "@pancakeswap/uikit";
import { useState } from "react";
import { balanceMaxMiniClass } from "./SwapWidget.css";
import { useTranslation } from "@pancakeswap/localization";

interface TradePriceProps {
  price?: Price<Currency, Currency>;
}

export function TradePrice({ price }: TradePriceProps) {
  const { t } = useTranslation();
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6);
  const assetA = price?.quoteCurrency?.symbol ? price?.quoteCurrency?.symbol : "";
  const assetB = price?.baseCurrency?.symbol ? price?.baseCurrency?.symbol : "";

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
  const label = showInverted
    ? t("%assetA% per %assetB%", { assetA: assetA, assetB: assetB })
    : t("%assetA% per %assetB%", { assetA: assetB, assetB: assetA });

  return (
    <Text style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
      {show ? (
        <>
          {formattedPrice ?? "-"} {label}
          {/* <RefreshIcon onClick={() => setShowInverted(!showInverted)} /> */}
        </>
      ) : (
        "-"
      )}
    </Text>
  );
}
