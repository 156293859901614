import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1454_97374)">
        <path d="M9.2 0H6.8L6 5.2H10M10 10.8H6L6.8 16H9.2" fill={props.fill} />
        <path
          d="M14.5049 3.19145L12.8079 1.4944L8.56523 4.60567L11.3937 7.43409M7.43386 11.3939L4.60543 8.56546L1.49416 12.8081L3.19122 14.5052"
          fill={props.fill}
        />
        <path d="M16 9.2V6.8L10.8 6V10M5.2 10V6L0 6.8V9.2" fill={props.fill} />
        <path
          d="M12.8076 14.5054L14.5046 12.8084L11.3934 8.56572L8.56493 11.3941M4.60513 7.43435L7.43356 4.60592L3.19092 1.49465L1.49386 3.19171"
          fill={props.fill}
        />
        <path
          d="M8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2V5.2C8.74261 5.2 9.4548 5.495 9.9799 6.0201C10.505 6.5452 10.8 7.25739 10.8 8C10.8 8.74261 10.505 9.4548 9.9799 9.9799C9.4548 10.505 8.74261 10.8 8 10.8C7.25739 10.8 6.5452 10.505 6.0201 9.9799C5.495 9.4548 5.2 8.74261 5.2 8C5.2 7.25739 5.495 6.5452 6.0201 6.0201C6.5452 5.495 7.25739 5.2 8 5.2"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1454_97374">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
