import { AtomBox } from "@pancakeswap/ui";
import { ReactNode } from "react";
import { Heading } from "../../components/Heading";
import { Text } from "../../components/Text";

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  icons: ReactNode;
}

export const CurrencyInputHeader = ({ title, subtitle, icons }: Props) => {
  return (
    <AtomBox width="full" alignItems="center" flexDirection="column" px="3">
      {title}
      {subtitle}
      {icons}
    </AtomBox>
  );
};

export const CurrencyInputHeaderTitle = ({ children }: { children: ReactNode }) => (
  <Heading scale="xl" as="h1">
    {children}
  </Heading>
);
export const CurrencyInputHeaderSubTitle = ({ children }: { children: ReactNode }) => (
  <Text color="textSubtle" bold fontSize="14px" textAlign="start">
    {children}
  </Text>
);
