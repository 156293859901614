import { ButtonProps, IconButton } from "../../components/Button";
import { ExchangeIcon } from "../../components/Svg";
import { switchButtonClass, iconDownClass, iconUpDownClass } from "./SwapWidget.css";
import { CurrencyInputPanel } from "./CurrencyInputPanel";
import { CurrencyInputHeader, CurrencyInputHeaderSubTitle, CurrencyInputHeaderTitle } from "./CurrencyInputHeader";
import { SwapPage } from "./Page";
import { SwapFooter } from "./Footer";
import { SwapInfo, SwapInfoLabel } from "./SwapInfo";
import { TradePrice } from "./TradePrice";
import styled, { css } from "styled-components";
import useTheme from "../../../../../apps/web/src/hooks/useTheme";

const IconButtonContainer = styled.div`
  border: 6px solid ${({ theme }) => theme.colors.background};
  border-radius: 12px;
  position: absolute;
  z-index: 100;
`;

const BoxIconRotate = styled.div`
  transform: rotate(180deg);
`;

const SwapSwitchButton = (props: ButtonProps) => {
  const { theme } = useTheme();

  return (
    <IconButtonContainer>
      <IconButton variant="light" scale="sm" {...props}>
        <BoxIconRotate>
          <ExchangeIcon fill={theme.colors.swapIcon} />
        </BoxIconRotate>
        <ExchangeIcon fill={theme.colors.swapIcon} />
      </IconButton>
    </IconButtonContainer>
  );
};

const Swap = {
  SwitchButton: SwapSwitchButton,
  CurrencyInputHeaderTitle,
  CurrencyInputHeaderSubTitle,
  CurrencyInputHeader,
  CurrencyInputPanel,
  Page: SwapPage,
  Footer: SwapFooter,
  Info: SwapInfo,
  InfoLabel: SwapInfoLabel,
  TradePrice,
};

export { Swap };
