import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon, NotificationDot, QuestionHelper } from '@pancakeswap/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
import Transactions from './Transactions'
import { SettingsMode } from '../Menu/GlobalSettings/types'

interface Props {
  title: string
  subtitle?: string
  helper?: string
  backTo?: string | (() => void)
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  padding: 24px 16px;
  width: 100%;
`

const ArrowBack = styled(ArrowBackIcon)`
  padding: 0;
  display: flex;
  align-self: start;
  height: 24px;
  width: 24px;
`

const HeadingStyled = styled(Heading)`
  font-size: 32px;
  font-weight: 700;
  font-family: 'IBM Plex Mono', monospace;
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()

  return (
    <AppHeaderContainer>
      {backTo &&
        (typeof backTo === 'string' ? (
          <Flex style={{ cursor: 'pointer' }} mb="16px">
            <Link passHref href={backTo}>
              <ArrowBack color="none" width="24px" />
            </Link>
            <Text>Back</Text>
          </Flex>
        ) : (
          <Flex style={{ cursor: 'pointer' }} mb="16px">
            <ArrowBack marginLeft="-5px" color="none" width="24px" onClick={backTo} />
            <Text>Back</Text>
          </Flex>
        ))}
      <Flex alignItems="center" width="100%" style={{ gap: '16px' }}>
        <Flex flexDirection="column" width="100%">
          <Flex mb="8px" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <HeadingStyled as="h2">{title}</HeadingStyled>
              {helper && <QuestionHelper text={helper} ml="4px" />}
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle" fontSize="16px" style={{ fontFamily: 'Roboto, sans-serif' }}>
              {subtitle}
            </Text>
            {!noConfig && (
              <Flex alignItems="center" style={{ gap: '8px' }}>
                <NotificationDot show={expertMode}>
                  <GlobalSettings color="#5E5E5E" mode={SettingsMode.SWAP_LIQUIDITY} />
                </NotificationDot>
                <Transactions />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
