import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.54038 9.45962C4.79422 9.71346 5.20578 9.71346 5.45962 9.45962L9.59619 5.32304C9.85003 5.0692 9.85003 4.65765 9.59619 4.40381C9.34235 4.14997 8.9308 4.14997 8.67696 4.40381L5 8.08076L1.32304 4.40381C1.0692 4.14997 0.657646 4.14997 0.403806 4.40381C0.149965 4.65765 0.149965 5.0692 0.403806 5.32304L4.54038 9.45962ZM5.65 1C5.65 0.641015 5.35898 0.35 5 0.35C4.64101 0.35 4.35 0.641015 4.35 1L5.65 1ZM5.65 9L5.65 1L4.35 1L4.35 9L5.65 9Z" {...props}/>
    </Svg>
  );
};

export default Icon;

