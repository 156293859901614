import { Currency } from '@pancakeswap/sdk'
import styled from 'styled-components'
import CurrencyLogo from './CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: ${({ margin }) => margin && '4px'};
`

const WrapperCurrencyLogo = styled.div<{ isLiquidityPage: boolean }>`
  margin-left: 8px;
  margin-right: ${({ isLiquidityPage }) => isLiquidityPage && '8px'};
  @media screen and (max-width: 390px) {
    margin-left: ${({ isLiquidityPage }) => isLiquidityPage && '0'};
    margin-right: ${({ isLiquidityPage }) => isLiquidityPage && '4px'};
  }
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
  isLiquidityPage?: boolean
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin = false,
  isLiquidityPage,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper margin={margin}>
      {currency0 && <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />}
      {currency1 && (
        <WrapperCurrencyLogo isLiquidityPage={isLiquidityPage}>
          <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
        </WrapperCurrencyLogo>
      )}
    </Wrapper>
  )
}
