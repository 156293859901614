import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#5E5E5E" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1782_1152)">
      <path
        d="M7.19922 4.7998V8.7998H7.24722L9.23122 10.7758L10.3592 9.64781L8.79922 8.0878V4.7998H7.19922Z"
        fill={props.fill}
      />
      <path
        d="M7.99844 0.799805C6.74822 0.80382 5.52059 1.13331 4.43638 1.75585C3.35217 2.37839 2.44875 3.27252 1.81504 4.35024C1.18132 5.42796 0.839157 6.65211 0.82222 7.90223C0.805282 9.15234 1.11415 10.3853 1.71844 11.4798L0.398438 12.7998H4.79844V8.3998L2.89444 10.3038C2.39706 9.20196 2.2696 7.96875 2.53109 6.78848C2.79259 5.6082 3.429 4.54423 4.34522 3.75558C5.26145 2.96692 6.40828 2.49593 7.61433 2.413C8.82038 2.33006 10.0209 2.63963 11.0364 3.29544C12.052 3.95126 12.8281 4.91809 13.2487 6.05147C13.6693 7.18484 13.7118 8.42389 13.3699 9.58344C13.0281 10.743 12.3201 11.7608 11.352 12.4847C10.3838 13.2086 9.20734 13.5998 7.99844 13.5998V15.1998C9.908 15.1998 11.7393 14.4412 13.0896 13.091C14.4399 11.7407 15.1984 9.90936 15.1984 7.9998C15.1984 6.09025 14.4399 4.2589 13.0896 2.90864C11.7393 1.55837 9.908 0.799805 7.99844 0.799805Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1782_1152">
        <rect width="16" height="16" fill={props.fill} />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
